import React, { useState } from 'react';
import i18n from '../i18n';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { IconWorld } from '@tabler/icons-react';

const LanguageSelector = () => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const chooseLanguage = (lang) => {
        i18n.changeLanguage(lang);

        handleClose();
    };

    return (
        <div style={{ paddingLeft: '20px', display: 'flex', alignItems: 'center' }}>
            <IconWorld onClick={handleClick} style={{ cursor: 'pointer', color: '#049734' }} stroke={2} size="1.5rem" />
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
                <MenuItem onClick={() => chooseLanguage('fr')}>Français</MenuItem>
                <MenuItem onClick={() => chooseLanguage('en')}>English</MenuItem>
                <MenuItem onClick={() => chooseLanguage('it')}>Italian</MenuItem>
            </Menu>
        </div>
    );
};

export default LanguageSelector;
