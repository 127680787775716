import { all } from 'redux-saga/effects';
import { authSagas } from './auth/sagas';
import { usersSagas } from './users/sagas';
import { notificationsSagas } from './notifications/sagas';
import { eventsSagas } from './events/sagas';
import { themeSagas } from './theme/sagas';
import { SuppliersSagas } from './Suppliers/sagas';
import { PartnersSagas } from './Partners/sagas';
import { achatsSagas } from './achat/sagas';
import { SparePartSaga } from './SpareParts/saga';
import { PlanningSaga } from './Planning/saga';
import { ProductSagas } from './Products/sagas';
import { CategorySagas } from './Categories/sagas';
import { ExportSagas } from './Export/sagas';
import { maintenancesSagas } from './maintenance/sagas';
import { ProductionsSagas } from './production/sagas';
import { OrdersSagas } from './Order/sagas';
import { Th } from '@chakra-ui/react';

export function* watchSagas() {
    yield all([
        themeSagas(),
        authSagas(),
        usersSagas(),
        notificationsSagas(),
        eventsSagas(),
        SuppliersSagas(),
        achatsSagas(),
        PartnersSagas(),
        SparePartSaga(),
        PlanningSaga(),
        ProductSagas(),
        CategorySagas(),
        ExportSagas(),
        maintenancesSagas(),
        ProductionsSagas(),
        OrdersSagas(),
        themeSagas()
    ]);
}
