/* eslint-disable no-unused-vars */
import { useDispatch, useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { styled, useTheme } from '@mui/material/styles';
import { AppBar, Box, Toolbar, useMediaQuery } from '@mui/material';
import Sidebar from './Sidebar';
import Header from './Header';
import { setMenuRequest } from 'store/theme/actions';

const drawerWidth = 275;
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    ...theme.typography.mainContent,
    flexGrow: 1,
    margin: theme.spacing(3),
    width: `calc(100% - ${drawerWidth}px)`,
    overflowX: 'hidden',
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen
    }),

    ...(!open && {
        borderBottomLeftRadius: 0,
        marginTop: 60,

        borderBottomRightRadius: 0,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        [theme.breakpoints.up('md')]: {
            marginRight: 'unset',
            marginLeft: -(drawerWidth - 10),
            width: `calc(100% - ${drawerWidth}px)`
        },
        [theme.breakpoints.down('md')]: {
            marginRight: 'unset',
            marginLeft: '20px',
            width: `calc(100% - ${drawerWidth}px)`
        },
        [theme.breakpoints.down('sm')]: {
            marginRight: 'unset',
            marginLeft: 'unset',
            width: `calc(100% - ${drawerWidth}px)`,
            padding: '16px'
        }
    }),
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
        }),

        marginTop: 60,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        width: `calc(100% - ${drawerWidth}px)`
    })
}));

// ==============================|| MAIN LAYOUT ||============================== //

function MainLayout() {
    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
    // Handle left drawer
    const leftDrawerOpened = useSelector((state) => state.theme.opened);
    const dispatch = useDispatch();
    const handleLeftDrawerToggle = () => {
        dispatch(setMenuRequest({ opened: !leftDrawerOpened }));
    };

    return (
        <Box sx={{ display: 'flex', backgroundColor: '#F0F2F5' }}>
            <AppBar
                enableColorOnDark
                position="fixed"
                color="inherit"
                elevation={0}
                sx={{
                    height: 60,
                    transition: theme.transitions.create(['width', 'margin'], {
                        easing: theme.transitions.easing.sharp,
                        duration: theme.transitions.duration.leavingScreen
                    }),
                    ...(leftDrawerOpened && {
                        marginLeft: `${drawerWidth}px`,
                        width: `calc(100% - ${drawerWidth}px)`
                    }),
                    ...(!leftDrawerOpened && {
                        marginLeft: 0,
                        width: '100%'
                    })
                }}
            >
                <Toolbar>
                    <Header handleLeftDrawerToggle={handleLeftDrawerToggle} />
                </Toolbar>
            </AppBar>

            {/* drawer */}
            <Sidebar drawerOpen={!matchDownMd ? leftDrawerOpened : !leftDrawerOpened} drawerToggle={handleLeftDrawerToggle} />

            {/* main content */}
            <Main theme={theme} open={leftDrawerOpened}>
                <Outlet />
            </Main>
        </Box>
    );
}

export default MainLayout;
